<template>
  <div class="jump-page">{{ title }}</div>
</template>
<script>
import { getPublicize } from '@/api/base';
import { getAppStatus } from '@/utils/app';

export default {
  name: 'SpdbJump',
  data() {
    return {
      userId: getAppStatus().cuserId,
    };
  },
  computed: {
    userIds() {
      return getAppStatus().cuserId;
    },
    title() {
      return document.referrer;
    },
  },
  mounted() {
    document.title = '';
    // let status = sessionStorage.getItem('add');
    // if (document.referrer !== '' || status) {
    //   window?.ios?.jzClose();
    //   window?.android?.jzClose();
    //   document.referrer = '';
    //   sessionStorage.removeItem('add');
    // } else {
    //   // window?.ios?.jzClose();
    // }
    console.log('come');
    this.getPublicize();
  },
  watch: {
  },
  methods: {
    async getPublicize() {
      let res;
      let data = { paramV: 'dddd' };
      const { query } = this.$route;
      try {
        res = await getPublicize(query);
      } catch (e) {
        console.log(e);
        this.$toast({
          content: e.desc,
        });
      }
      console.log(res);
      if (res.code === 1) {
        this.linkUrl = res?.results?.linkUrl;
        this.channel = res?.results?.channel;
        if (this.linkUrl) {
          sessionStorage.setItem('add', 1);
          // window.close();
          // window.location.href = this.linkUrl;
          // console.log(this.linkUrl);
          // }, 800);
          window.location.replace(this.linkUrl);
        }
      }
    },
  },
};
</script>
<style scoped>
  .jump-page {
    position: absolute;
    left: 0;
    top: 0;
    bottom: 0;
    right: 0;
    width: 100%;
    height: 100%;
    background-color: #fff;
  }
</style>
